import React, { useContext } from "react"
import { navigate } from "gatsby"
import { tracking } from "utils/tracking"
import { ModeType, $MediaCardPair, $HasContent } from "typings/modules"
import { SanityFiftyFiftyCard } from "typings/graphql"
import { Container, InnerContainer, Caption, Left, Right } from "./Card.styles"
import { ThemeContext, ThemeProvider } from "styled-components"
import { ResponsiveAsset } from "components/ResponsiveAsset/ResponsiveAsset"

type Props = {
  item: SanityFiftyFiftyCard
} & $MediaCardPair &
  $HasContent

export const Card = ({ item, $mediaCardPair, $hasContent }: Props) => {
  const themeContext = useContext(ThemeContext)
  const mode = item?.mode as ModeType
  const leftCaption = item?.leftCaption
  const rightCaption = item?.rightCaption
  const link = item?.link
  const internalName = item?.internalName || ""
  const bgColor = item?.bgColor?.opacityHex
  return (
    <ThemeProvider theme={{ localTheme: themeContext[mode] }}>
      <Container
        $bgColor={bgColor}
        $mediaCardPair={$mediaCardPair}
        $hasContent={$hasContent}
        base="1 [10] 1"
        md="2 [12] 2"
        lg="2 [8] 2"
      >
        <InnerContainer
          onClick={
            link
              ? () => {
                  tracking.elementClicked(internalName, "Hero")
                  navigate(link)
                }
              : undefined
          }
          $hasLink={!!link}
        >
          <ResponsiveAsset responsiveAsset={item?.asset} />
          {leftCaption || rightCaption ? (
            <Caption>
              {leftCaption && <Left>{leftCaption}</Left>}
              {rightCaption && <Right>{rightCaption}</Right>}
            </Caption>
          ) : null}
        </InnerContainer>
      </Container>
    </ThemeProvider>
  )
}

export default Card
