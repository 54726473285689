import styled, { css } from "styled-components"
import { mq, typography } from "styles"
import { Grid } from "components/UI/Grid/Grid"
import {
  $HasLink,
  $MediaCardPair,
  $BgColor,
  $HasContent,
} from "typings/modules"

export const Container = styled(Grid)<$BgColor & $MediaCardPair & $HasContent>`
  background: ${({ $bgColor }) => $bgColor};
  height: 100%;
  align-items: center;
  padding: ${({ $mediaCardPair, $hasContent }) =>
    $mediaCardPair || !$hasContent ? "64px 0 0" : "0 0 64px"};
  ${mq.minWidth("md")} {
    padding: ${({ $mediaCardPair, $hasContent }) =>
      $mediaCardPair || !$hasContent ? "96px 0 0" : "0 0 96px"};
  }
  ${mq.minWidth("lg")} {
    padding: ${({ $hasContent }) => ($hasContent ? "0 0 96px" : "96px 0")};
  }
`

export const InnerContainer = styled.div<$HasLink>`
  position: relative;
  height: 100%;
  ${({ $hasLink }) =>
    $hasLink &&
    css`
      cursor: pointer;
    `}
`

export const Caption = styled.div`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  display: flex;
  margin-top: 16px;
`

export const Left = styled.div``

export const Right = styled.div`
  margin-left: auto;
`
