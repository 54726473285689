import React from "react"
import loadable from "@loadable/component"
import { MemoizedImage } from "components/UI/MemoizedImage"
import {
  SanityImageAsset,
  SanityVideoAsset,
  SanityResponsiveAsset,
  SanityHotspotAsset,
} from "typings/graphql"
import { getImage } from "utils/imageUtils"
import {
  Container,
  AssetContainer,
  HoverContainer,
} from "./ResponsiveAsset.styles"
import {
  LargeAndUp,
  MediumAndBelow,
} from "components/UI/MediaQueryContainers/MediaQueryContainers"
const Video = loadable(() => import("components/UI/Video/Video"))

type Props = {
  responsiveAsset: SanityResponsiveAsset
}

export const ResponsiveAsset = ({ responsiveAsset }: Props) => {
  return (
    <Container>
      <Asset responsiveAsset={responsiveAsset} />
      <HoverAsset responsiveAsset={responsiveAsset} />
    </Container>
  )
}

const Asset = ({ responsiveAsset }: Props) => {
  if (!responsiveAsset) {
    return null
  }

  const hasHoverAsset = !!responsiveAsset.hoverAsset?.length
  const desktopAsset = responsiveAsset.desktopAsset[0]
  const mobileAsset = responsiveAsset.mobileAsset[0] || desktopAsset

  return (
    <div>
      {!!mobileAsset && (
        <MediumAndBelow>
          <AssetContainer>
            <Content asset={mobileAsset} />
          </AssetContainer>
        </MediumAndBelow>
      )}
      {!!desktopAsset && (
        <LargeAndUp style={{ height: "100%" }}>
          <AssetContainer $hoverAsset={hasHoverAsset}>
            <Content asset={desktopAsset} />
          </AssetContainer>
        </LargeAndUp>
      )}
    </div>
  )
}

const HoverAsset = ({ responsiveAsset }: Props) => {
  const asset = responsiveAsset?.hoverAsset?.[0]

  return (
    !!asset && (
      <HoverContainer>
        <Content asset={asset} />
      </HoverContainer>
    )
  )
}

const Content = ({
  asset,
}: {
  asset: SanityImageAsset | SanityHotspotAsset | SanityVideoAsset
}) => {
  const imageAsset = asset as SanityImageAsset
  const altText = imageAsset.altText
  const image = getImage(imageAsset.image)
  const vidAsset = asset as SanityVideoAsset
  const vidId = vidAsset?.video?.asset?.playbackId
  const autoplay = vidAsset?.autoplay

  return (
    <>
      {image ? (
        <div>
          <MemoizedImage image={image?.gatsbyImage} alt={altText} />
        </div>
      ) : (
        !!vidId && <Video playbackId={vidId} autoplay={autoplay} />
      )}
    </>
  )
}

export default Asset
