// resolves gatsby-plugin-image flickering on hover bug
// https://github.com/gatsbyjs/gatsby/issues/30952

import React from "react"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import styled from "styled-components"

const BlockGatsbyImage = styled(GatsbyImage)`
  display: block;
  &.gatsby-image-wrapper-constrained {
    display: block;
  }
`

export const MemoizedImage = React.memo((props: GatsbyImageProps) => (
  <BlockGatsbyImage {...props} />
))
