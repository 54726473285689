import styled from "styled-components"
import { mq } from "styles"
import { $HoverAsset } from "typings/modules"

export const Container = styled.div`
  position: relative;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .gatsby-image-wrapper {
    display: block;
  }
`

export const HoverContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`
export const AssetContainer = styled.div<Partial<$HoverAsset>>`
  opacity: 1;
  transition: opacity 0.3s ease;
  position: relative;
  z-index: 1;
  height: 100%;
  ${mq.minWidth("lg")} {
    &:hover {
      opacity: ${({ $hoverAsset }) => ($hoverAsset ? 0 : 1)};
    }
  }
`
